import { Suspense, lazy } from "react";
import { HashRouter as Router, Routes as ReactRouterRoutes, Route, Outlet } from "react-router-dom";
import { Backdrop, CircularProgress } from "@mui/material";
import { LoginWithMicrosoft, NotFound, LoginWithToken } from "@sal/portal";
import BasePage from "./pages/BasePage";
import { LoginPage } from "./pages/LoginPage";
import { ChangePasswordPage } from "./pages/ChangePasswordPage";
import { routePermissionMapping } from "./Settings";
import {
	PortalAuthenticatedRoute as _PortalAuthenticatedRoute,
	PortalAuthenticatedRouteProps,
	usePortalApplicationContext
} from "@sal/onevent-portal";
import { ForgotPasswordPage } from "./pages/ForgotPasswordPage";
import { ResetPasswordPage } from "./pages/ResetPasswordPage";
import { useAuth } from "@sal/authentication.react";

const HomePage = lazy(() => import("./pages/HomePage"));
const ShipmentDashboardPage = lazy(() => import("./pages/ShipmentDashboardPage"));
const ShipmentTrackerPage = lazy(() => import("./pages/ShipmentTrackerPage"));
const TransportPage = lazy(() => import("./pages/TransportPage"));
const TransportDetailsPage = lazy(() => import("./pages/TransportDetailsPage"));
const AccountsPage = lazy(() => import("./pages/AccountsPage"));
const ImportOrderPage = lazy(() => import("./pages/ImportOrderPage"));
const AnalyticsPage = lazy(() => import("./pages/AnalyticsPage"));
const ReportsPage = lazy(() => import("./pages/ReportsPage"));
const EstimatePage = lazy(() => import("./pages/EstimatePage"));
const MyProfile = lazy(() => import("./pages/myProfile/MyProfile"));
const UserDashboardPage = lazy(() => import("./pages/UserDashboardPage"));
const UserDetailPage = lazy(() => import("./pages/UserDetailPage"));
const UserNewPage = lazy(() => import("./pages/UserNewPage"));
const SchedulePage = lazy(() => import("./pages/SchedulesPage"));
const ContainerPage = lazy(() => import("./pages/ContainerTrackerPage"));
const OrderTrackerPage = lazy(() => import("./pages/OrderTrackerPage"));
const DeliveryTrackingPage = lazy(() => import("./pages/DeliveryTrackingPage"));
const MyAccountPage = lazy(() => import("./pages/MyAccountPage"));
const AdminPage = lazy(() => import("./pages/AdminPage"));
const TEUCalendarPage = lazy(() => import("./pages/TEUCalendarPage"));
const StaffContactsPage = lazy(() => import("./pages/StaffContactsPage"));
const ExportBookingPage = lazy(() => import("./pages/ExportBookingPage"));

const {
	track,
	order,
	estimate,
	accounts,
	transport,
	analytics,
	reports,
	schedule,
	users,
	admin,
	teuCalendar,
	staffContacts,
	exportBooking
} = routePermissionMapping;

function PortalAuthenticatedRoute(props: PortalAuthenticatedRouteProps) {
	const auth = useAuth();
	const loginPath = auth.authenticationMethod === "SalCore" ? "/LoginSalCore" : "/Login";

	return <_PortalAuthenticatedRoute {...props} loginPath={loginPath} />;
}

export default function Routes() {
	const { organisations } = usePortalApplicationContext();

	return (
		<Router>
			<ReactRouterRoutes>
				<Route element={<PortalAuthenticatedRoute />}>
					<Route element={<BasePage />}>
						<Route
							element={
								<Suspense
									fallback={
										<Backdrop invisible open>
											<CircularProgress />
										</Backdrop>
									}
								>
									<Outlet />
								</Suspense>
							}
						>
							<Route path="/" element={<HomePage />} />
							<Route
								element={
									<PortalAuthenticatedRoute
										securityContext={track.securityContext}
										securityContextPermission={track.securityContextPermission}
									/>
								}
							>
								<Route path={track.path} element={<ShipmentDashboardPage />} />
								<Route path="/Shipments" element={<ShipmentTrackerPage />} />
								<Route path="/Containers" element={<ContainerPage />} />
								<Route path="/Orders" element={<OrderTrackerPage />} />
								<Route path="/DeliveryTracking" element={<DeliveryTrackingPage />} />
							</Route>
							<Route
								path={teuCalendar.path}
								element={
									<PortalAuthenticatedRoute
										securityContext={teuCalendar.securityContext}
										securityContextPermission={teuCalendar.securityContextPermission}
									>
										<TEUCalendarPage />
									</PortalAuthenticatedRoute>
								}
							/>
							<Route
								path={order.path}
								element={
									<PortalAuthenticatedRoute
										securityContext={order.securityContext}
										securityContextPermission={order.securityContextPermission}
									>
										<ImportOrderPage />
									</PortalAuthenticatedRoute>
								}
							/>
							<Route
								path={exportBooking.path}
								element={
									<PortalAuthenticatedRoute
										securityContext={exportBooking.securityContext}
										securityContextPermission={exportBooking.securityContextPermission}
									>
										<ExportBookingPage />
									</PortalAuthenticatedRoute>
								}
							/>
							<Route
								path={estimate.path}
								element={
									<PortalAuthenticatedRoute
										securityContext={estimate.securityContext}
										securityContextPermission={estimate.securityContextPermission}
									>
										<EstimatePage />
									</PortalAuthenticatedRoute>
								}
							/>
							<Route
								path={accounts.path}
								element={
									<PortalAuthenticatedRoute
										securityContext={accounts.securityContext}
										securityContextPermission={accounts.securityContextPermission}
									>
										<AccountsPage />
									</PortalAuthenticatedRoute>
								}
							/>
							<Route
								path={transport.path}
								element={
									<PortalAuthenticatedRoute
										securityContext={transport.securityContext}
										securityContextPermission={transport.securityContextPermission}
									/>
								}
							>
								<Route index element={<TransportPage />} />
								<Route path=":orgId/:partyId/:orgCode/:partyRef" element={<TransportDetailsPage />} />
							</Route>
							<Route
								path={analytics.path}
								element={
									<PortalAuthenticatedRoute
										securityContext={analytics.securityContext}
										securityContextPermission={analytics.securityContextPermission}
									>
										<AnalyticsPage />
									</PortalAuthenticatedRoute>
								}
							/>
							<Route
								path={reports.path}
								element={
									<PortalAuthenticatedRoute
										securityContext={reports.securityContext}
										securityContextPermission={reports.securityContextPermission}
									>
										<ReportsPage />
									</PortalAuthenticatedRoute>
								}
							/>
							<Route
								path={schedule.path}
								element={
									<PortalAuthenticatedRoute permissions={schedule.permissions || []}>
										<SchedulePage />
									</PortalAuthenticatedRoute>
								}
							/>
							<Route
								path={admin.path}
								element={
									<PortalAuthenticatedRoute
										securityContext={admin.securityContext}
										securityContextPermission={admin.securityContextPermission}
									>
										<AdminPage />
									</PortalAuthenticatedRoute>
								}
							/>
							<Route
								path={staffContacts.path}
								element={
									<PortalAuthenticatedRoute
										securityContext={staffContacts.securityContext}
										securityContextPermission={staffContacts.securityContextPermission}
									>
										<StaffContactsPage />
									</PortalAuthenticatedRoute>
								}
							/>
							<Route
								path={users.path}
								element={
									<PortalAuthenticatedRoute
										securityContext={users.securityContext}
										securityContextPermission={users.securityContextPermission}
									/>
								}
							>
								<Route index element={<UserDashboardPage />} />
								<Route path=":id" element={<UserDetailPage />} />
								<Route
									path="new"
									element={
										<PortalAuthenticatedRoute
											securityContext="apI_User"
											securityContextPermission="Write"
										>
											<UserNewPage />
										</PortalAuthenticatedRoute>
									}
								/>
							</Route>
							<Route path="/MyProfile" element={<MyProfile />} />
							<Route path="/MyAccount" element={<MyAccountPage />} />
						</Route>
					</Route>
				</Route>
				{/* PORTAL FRAMEWORK PAGES*/}
				<Route path="/LoginWithToken/:token/:returnUrl?" element={<LoginWithToken />} />
				<Route
					path="/ChangePassword"
					element={
						<PortalAuthenticatedRoute
							securityContext="web_Host"
							securityContextPermission="Change_Password"
						>
							<ChangePasswordPage />
						</PortalAuthenticatedRoute>
					}
				/>
				<Route path="/LoginSalCore" element={<LoginPage />} />
				<Route
					path="/Login"
					element={
						<LoginWithMicrosoft
							onLogin={() => {
								organisations.clearOrganisations();
							}}
						/>
					}
				/>
				<Route path="/ForgotPassword" element={<ForgotPasswordPage />} />
				<Route path="/ResetPassword/:passwordResetToken" element={<ResetPasswordPage />} />
				<Route path="/NotFound" element={<NotFound />} />
				<Route path="*" element={<NotFound />} />
				{/* /PORTAL FRAMEWORK PAGES*/}
			</ReactRouterRoutes>
		</Router>
	);
}

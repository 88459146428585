import { Box, Button, Typography, useTheme } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { VerticalNavigationComponentProps } from "@sal/onevent-portal";
import { useNavigate } from "react-router-dom";
import { getTerm } from "../locale/index";
import { useAuth } from "@sal/authentication.react";

export function VerticalNavigationActionButton({ collapsed }: VerticalNavigationComponentProps) {
	const theme = useTheme();
	const navigate = useNavigate();

	// NOTE - MVP we are not using permissions to control new order. For MVP users cannot create orders
	const auth = useAuth();
	// const canCreateNewOrder = auth.userHasPermission("apI_PortalOrder", "Write");
	// const canCreateNewBooking = auth.userHasPermission("apI_PortalShipment", "Write");
	const canCreateNewOrder = false;
	const canCreateNewBooking = false;

	return (
		<>
			<Box alignItems="center" justifyContent="center" width="100%" paddingX={collapsed ? 0 : 6}>
				<Box
					display="flex"
					alignItems="center"
					justifyContent="center"
					width="100%"
					paddingX={collapsed ? 0 : 6}
				>
					{canCreateNewOrder && (
						<Button
							variant="contained"
							color="primary"
							fullWidth={!collapsed}
							title={getTerm("navigation_NewOrder")}
							sx={{
								paddingY: collapsed ? theme.spacing(2) : theme.spacing(3),
								minWidth: collapsed ? "auto" : "initial"
							}}
							onClick={() => navigate({ pathname: "/ImportOrder" })}
						>
							<Typography
								fontSize={collapsed ? "large" : "0.85rem"}
								color={theme.palette.common.white}
								sx={{ display: "flex", alignItems: "center", gap: 1 }}
							>
								<AddIcon fontSize="inherit" />
								{!collapsed && <span>{getTerm("navigation_NewOrder")}</span>}
							</Typography>
						</Button>
					)}
				</Box>

				{canCreateNewBooking && (
					<Box
						display="flex"
						alignItems="center"
						justifyContent="center"
						width="100%"
						paddingX={collapsed ? 0 : 6}
						paddingTop={2}
					>
						<Button
							variant="contained"
							color="primary"
							fullWidth={!collapsed}
							title={getTerm("navigation_NewBooking")}
							sx={{
								paddingY: collapsed ? theme.spacing(2) : theme.spacing(3),
								minWidth: collapsed ? "auto" : "initial"
							}}
							onClick={() => navigate({ pathname: "/ExportBooking" })}
						>
							<Typography
								fontSize={collapsed ? "large" : "0.85rem"}
								color={theme.palette.common.white}
								sx={{ display: "flex", alignItems: "center", gap: 1 }}
							>
								<AddIcon fontSize="inherit" />
								{!collapsed && <span>{getTerm("navigation_NewBooking")}</span>}
							</Typography>
						</Button>
					</Box>
				)}
			</Box>
		</>
	);
}

/* eslint-disable max-len */

const format = {
	"format_decimalSeparator": ".",
	"format_digitGroupSeparator": ",",
	"format_digitGroupSeparatorInterval": 3,
	"format_decimalPlaces": 2,
	"format_termSeparator": ", ",
	"format_conjunction": " and ",
	"format_disjunction": " or ",
	"format_datetime": "DD MMM YYYY hh:mm a",
	"format_date_data": "DD MMM YYYY",
	"format_date_short": "DD MMM YYYY",
	"format_date_long": "DD MMMM YYYY",
	"format_date_shortest": "DD MMM",
	"format_time_data": "HH:mm",
	"format_time_short": "HH:mm",
	"format_time_long": "HH:mm:ss",
	"format_time_shortest": "HH:mm",
	"format_true": "Yes",
	"format_false": "No"
};

const base = {
	"projectTitle": "MVGLVisibility",
	"defaultAgent": "MONDIALE VGL",
	"newOrder_Title_Edit": "New Order",
	"newOrder_Title_Booking": "New Booking",
	"navigation_NewOrder": "New Order",
	"navigation_NewBooking": "New Booking",
	"newOrder_Button_CreateBooking": "Create Booking",
	"newOrder_Button_CreateOrder": "Create Order",
	"newOrder_DialogHeader_BookingSuccessfullyRequested": "Booking successfully requested!",
	"newOrder_DialogText_BookingReceived": "Your booking has been received and will be displayed under your Orders once it has been processed.",
	"newOrder_DialogButton_BackToOrders": "Back To Orders",
	"newOrder_DialogButton_BackToEstimates": "Back To Estimates",
	"newEstimate_Button_Calculate": "Calculate",
	"newEstimate_Title_Edit": "New Estimate",
	"newEstimate_Title_Grid": "Estimates",
	"newEstimate_Summary_Title": "Details",
	"newEstimate_LCLGrid_Title": "LCL (Less than Container) Details",
	"newEstimate_ContainerGrid_Title": "FCL (Full Container Load) Details",
	"newEstimate_Summary_Containers_Header": "Containers",
	"newEstimate_Summary_LCL_Header": "LCL",
	"newEstimate_Summary_Disclaimer_Title_Text": "Disclaimer",
	"newEstimate_Summary_Disclaimer_Caption_Text": "Any rates shown are an approximation only, are based on the current rate of exchange which is susceptible to variation, and unless mentioned, are exclusive of any duties, taxes, incidentals, disbursements, and government fees if and where applicable. Rates are only valid for the period stated and are subject to change at any time with/without notice",
	"newEstimate_Summary_Disclaimer_Caption_No_Results_Text": "No rates were found based on the criteria: To request a quotation, kindly contact your nominated Mondiale VGL representative.",
	"newEstimate_Summary_Button_Request_Price": "Request Price",
	"newEstimate_Summary_Button_Book_Anyway": "Book Anyway",
	"newEstimate_DialogHeader_BookingRequestPrice": "Price successfully requested",
	"newEstimate_DialogText_BookingRequestPrice": "Your price request has been succesfully submitted and a member of our customer services team will be in touch.",
	"newEstimate_DialogButton_BackToEstimates": "Back To Estimates",
	"cargoType": "Commodity",
	...format
};

export { base };
